<template>

    <div class="home">
        <div class="inner">

            <h1 v-html="title"
                data-aos="zoom-in"
                data-aos-anchor=".home"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-out-quad"></h1>

            <interactive-nav-list></interactive-nav-list>

            <div class="legal"
                 data-aos="fade"
                 data-aos-anchor=".home"
                 data-aos-delay="2000"
                 data-aos-duration="1200"
                 data-aos-easing="ease-out-quad">
                <div class="disclaimer"
                     v-if="disclaimer"
                     v-html="disclaimer"></div>
                <div class="copyright"
                     v-if="copyright"
                     v-html="copyright"></div>
            </div>

        </div>
        <div class="cloud-left"
             data-aos="fade-right"
             data-aos-anchor=".home"
             data-aos-delay="300"
             data-aos-duration="3000"
             data-aos-easing="ease-out-quad"></div>
        <div class="cloud-right"
             data-aos="fade-left"
             data-aos-anchor=".home"
             data-aos-delay="300"
             data-aos-duration="3000"
             data-aos-easing="ease-out-quad"></div>
    </div>

</template>


<script>

import InteractiveNavList from '@/components/InteractiveNavList';

export default {

    name: 'Home',

    components: {
        InteractiveNavList
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            title: '<span>National Geographic</span> Everest Educational Interactives',
            disclaimer: '<span><em>For educational use only</em></span><br /> Make sure you check with an experienced guide before attempting to summit Mount Everest.<br /><a href="https://storymaps.arcgis.com/stories/fb63ef8f36a54433bfbf6f61e621c7ad" target="_blank" class="storymap-link">Expedition Everest</a>',
            copyright: '&copy; ' + new Date().getFullYear() + '. All Rights Reserved.'
        };
    }

}

</script>


<style lang="scss" scoped>

.home {
    background-color: $sherpa-bg-color;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;

    & .cloud-left,
    & .cloud-right {
        content: '';
        position: fixed;
        top: 0;
        pointer-events: none;
    }

    & .cloud-left {
        //background-color: rgba(0,121,0,0.5);
        background-image: url('../assets/images/sherpa-speak/ss_cloud-top-left.webp');
        background-size: 477px 396px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 477px;
        height: 396px;
        left: -250px;
        z-index: 1;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            left: -120px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            left: 0;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .cloud-right {
        //background-color: rgba(44,121,118,0.5);
        background-image: url('../assets/images/sherpa-speak/ss_cloud-top-right.webp');
        background-size: 376px 591px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 376px;
        height: 591px;
        right: -120px;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            right: -80px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            right: 0;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & > .inner {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
        padding: 3rem 1.25rem;
        position: relative;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            padding: 5rem 1.25rem;
        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & > * {
            position: relative;
            z-index: 3;
        }

        &::before {
            content: '';
            background-image: url('../assets/images/oxygen-calculator/img-landing-mtn.png');
            background-size: cover;
            background-position: 50% 1.75rem;
            background-repeat: no-repeat;
            mask-image: radial-gradient(ellipse 100% 90% at 50% 30%, black 30%, transparent 50%);
            width: 100%;
            height: 100%;
            opacity: 0.76;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            @media (min-width: 576px) { // RED (SM)
                background-position: 50% 2rem;
            }
            @media (min-width: 768px) { // GREEN (MD)
                background-position: 50% 4rem;
            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

    }
}

h1 {
    font-size: clamp(2.50rem, 3vw, 6.563rem);
    font-weight: 700;
    text-transform: uppercase;
    color: $sherpa-title-color-highlight;
    text-shadow: 0 0 1.25rem $white-100;
    margin: 0 0 3rem 0;

    @media (min-width: 576px) { // RED (SM)

    }
    @media (min-width: 768px) { // GREEN (MD)
        margin: 0 0 4rem 0;
    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & ::v-deep span {
        font-size: clamp(1.50rem, 3vw, 3.125rem);
        font-weight: 600;
        color: $sherpa-title-color;
        display: block;
        margin: 0 0 0.5rem 0;
    }
}

.legal {
    font-size: 0.875rem;
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    padding: 3rem 1.5rem 0;

    @media (min-width: 576px) { // RED (SM)

    }
    @media (min-width: 768px) { // GREEN (MD)
        padding: 4rem 3rem 0;
    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & .disclaimer {
        line-height: 1.375;
        margin: 0 0 1rem 0;

        &::v-deep {

            & .storymap-link {
                font-weight: 700;
                color: $sherpa-title-color-highlight;
                margin: 0.5rem 0;
                display: inline-block;

                @media(hover: hover) {
                    &:hover {
                        color: darken($sherpa-title-color-highlight, 10%);
                    }
                }
            }
        }
    }

    & .copyright {
        font-size: 0.75rem;
        line-height: 1;
    }
}

</style>
