/* OXYGEN-CALCULATOR */

import { SET_CURRENT_STEP } from './actions';
import { SET_BOTTLES } from './actions';
import { SET_FLOW_RATE } from './actions';
import { UPDATE_CURRENT_STEP } from './mutations';
import { UPDATE_BOTTLES } from './mutations';
import { UPDATE_FLOW_RATE } from './mutations';

import Step1Content from '@/components/oxygen-calculator/Step1Content';
import Step2Content from '@/components/oxygen-calculator/Step2Content';
import Step3Content from '@/components/oxygen-calculator/Step3Content';
import Step4Content from '@/components/oxygen-calculator/Step4Content';

const state = {
    landingContent: {
        title: 'This is My Title From the "oxygen-calculator" Vuex State Store Module',
        description: '<p>This is my <em>description</em> from the <strong>"oxygen-calculator"</strong> Vuex State Store module...</p>'
    },
    currentStep: null,
    tutorialSteps: [
        {
            step: 1,
            component: Step1Content
        }, {
            step: 2,
            component: Step2Content
        }, {
            step: 3,
            component: Step3Content
        }, {
            step: 4,
            component: Step4Content
        }
    ],
    flowRate: 1,
    bottles: 1
};

const getters = {

    landingContent: ({ landingContent }) => landingContent || null,

    currentStep: ({ currentStep, tutorialSteps }) => currentStep || tutorialSteps[0],

    onBoardingSteps: ({ tutorialSteps }) => tutorialSteps || [],

    flowRate: ({ flowRate }) => flowRate || 1,

    bottles: ({ bottles }) => bottles || 1

}

const mutations = {

    [UPDATE_CURRENT_STEP](state, payload) {
        state.currentStep = payload;
    },

    [UPDATE_BOTTLES](state, payload) {
        state.bottles = payload;
    },

    [UPDATE_FLOW_RATE](state, payload) {
        state.flowRate = payload;
    }

};

const actions = {

    [SET_CURRENT_STEP]({ commit }, payload) {
        commit(UPDATE_CURRENT_STEP, payload);
    },

    [SET_BOTTLES]({ commit }, payload) {
        commit(UPDATE_BOTTLES, payload);
    },

    [SET_FLOW_RATE]({ commit }, payload) {
        commit(UPDATE_FLOW_RATE, payload);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
