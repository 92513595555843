<template>

    <div class="card" :class="{ 'clouds': (clouds.left.enabled || clouds.right.enabled), 'hiddenLeft': !clouds.left.enabled, 'hiddenRight': !clouds.right.enabled, 'small': (clouds.left.pinTo === 'sm' || clouds.right.pinTo === 'sm') }">
        <div class="inner">
            <slot></slot>
        </div>
    </div>

</template>


<script>

export default {

    name: 'Card',

    props: {
        clouds: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {

        }
    }

}

</script>


<style lang="scss" scoped>
    .card {
        background: linear-gradient(135deg,  rgba(254,254,254, 0.9) 1%, rgba(234,237,247, 0.9) 100%);
        border-radius: 20px;
        border: 1px solid white;
        box-shadow: 0 0 50px rgba(6,7,34, 0.15);
        padding: 2rem;
        position: relative;

        &.hidden {
            background: transparent;
            border: none;
            box-shadow: none;
        }

        &.clouds {

            &::before,
            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                pointer-events: none;
            }

            &::before {
                background: transparent url('../../assets/images/oxygen-calculator/img-card-cloud-top-left.png') 0 0 no-repeat;
                background-size: cover;
                width: 474px;
                height: 303px;
                top: -65px;
                left: -170px;
            }

            &::after {
                background: transparent url('../../assets/images/oxygen-calculator/img-card-cloud-btm-right.png') 0 0 no-repeat;
                background-size: cover;
                width: 507px;
                height: 285px;
                right: -225px;
                bottom: -185px;
            }

            &.small {
                &::before {
                    width: 206px;
                    height: 129px;
                    top: -30px;
                    left: -85px;
                }

                &::after {
                    width: 266px;
                    height: 191px;
                    right: -70px;
                    top: -55px;
                }
            }

            &.hidden,
            &.hiddenLeft {

                &::before {
                    display: none;
                }
            }

            &.hidden,
            &.hiddenRight {

                &::after {
                    display: none;
                }
            }
        }

        & > .inner {
            position: relative;
            z-index: 10;
            width: 100%;
        }
    }
</style>