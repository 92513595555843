import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta';
import EventBusPlugin from './components/utilities/EventBusPlugin';
import AOS from 'aos';

// plugin styles
import 'aos/dist/aos.css'

// global styles
import './styles/main.scss';

Vue.use(VueMeta);
Vue.use(EventBusPlugin);

Vue.config.productionTip = false;

// hides warnings in console - 8th Wall recommends this
Vue.config.ignoredElements = [
  'a-scene',
  'a-asset-item',
  'a-image',
  'a-curvedimage',
  'a-assets',
  'a-text',
  'a-plane',
  'a-cylinder',
  'a-rounded',
  'a-light',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-cursor',
  'a-gltf-model',
  'a-triangle',
  'a-cubemap'
]

require('aframe')


new Vue({
    router,
    store,
    created() {
        AOS.init({
            disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
            duration: 1200,
            easing: 'ease-out-quad',
            mirror: false,
            anchorPlacement: 'top-bottom'
        });
    },
    render: h => h(App)
}).$mount('#app');
