import Vue from 'vue';
import Vuex from 'vuex';

import ArTerrain from '@/store/arTerrain';
import HumanBody from '@/store/humanBody';
import OxygenCalculator from '@/store/oxygenCalculator';
import SherpaSpeak from '@/store/sherpaSpeak';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        awesome: true
    },
    modules: {
        ArTerrain,
        HumanBody,
        OxygenCalculator,
        SherpaSpeak
    }
});

export default store;
