<template>

    <div class="step2content" :class="{'tablet' : tablet}">
    
        <h1>Plan Your Route In<br>Augmented Reality</h1>
        <img src="@/assets/images/ar-terrain/img-ar-step2-content.webp">
        <h2>North Ridge Route</h2>
        <h3>22 <span>mi / </span>36.5<span> km </span><span>To Summit</span></h3>
        <p class="mt-1">The North Ridge Route is where George Mallory disappeared in 1924 and where a Chinese team finally completed the climb in 1960.</p>
        
    </div>

</template>


<script>

export default {

    name: 'Step2Content',

    props: ['tablet'],
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
}

</script>


<style lang="scss" scoped>

.step2content {
    color: #4d5384;
    font-size: 13px;
    line-height: 23px;
    font-weight: $font-weight-medium;
    height: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        height: 365px;
    }
    
    @media only screen
    and (min-device-width : 360px)
    and (max-device-width : 640px)
    and (-webkit-min-device-pixel-ratio: 4) { 
        height: 365px;
    }

    &.tablet {
        height: 700px;
        font-size: 24px;
        line-height: 40px;

        & h1 {
            font-size: 26px;
            line-height: 30px;
            margin: 20px 0 0;
        }

        & img {
            width: 620px;
            margin-top: 20px;
        }

        & p {
            padding: 30px 0 0 0;
        }
    }

    & h1 {
        color: #0f005d;
        font-size: 16px;
        font-weight: $font-weight-extrabold;
        line-height: 20px;
        margin: 10px 0 0;
        text-transform: uppercase;
    }

    & h1 {
        color: #0f005d;
        font-size: 16px;
        font-weight: $font-weight-extrabold;
        line-height: 20px;
        margin: 0;
        text-transform: uppercase;
    }

    & h2 {
        color: #101f96;
        font-size: 14px;
        font-weight: $font-weight-extrabold;
        line-height: 20px;
        margin: 5px 0 0;
        text-transform: uppercase;
    }

    & h3 {
        color: #00108f;
        font-size: 15px;
        font-weight: $font-weight-extrabold;
        line-height: 20px;
        margin: 0;
        text-transform: uppercase;

        & span {
            font-size: 12px;
            font-weight: $font-weight-semibold;
        }

        & span:nth-child(3) {
            font-weight: $font-weight-medium;
        }
    }

    & img {
        width: 355px;
        margin-top: 5px;

        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) {
            width: 300px;
        }
        
        @media only screen
        and (min-device-width : 360px)
        and (max-device-width : 640px)
        and (-webkit-min-device-pixel-ratio: 4) { 
            width: 300px;
        }
    }

    & p {
        margin: 0;
        max-width: 600px;
        margin: 0 auto;
        padding: 0 35px;

        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0 15px;
        }
        
        @media only screen
        and (min-device-width : 360px)
        and (max-device-width : 640px)
        and (-webkit-min-device-pixel-ratio: 4) { 
            padding: 0 15px;
        }
    }
}

</style>
