<template>

    <transition name="fade" mode="out-in">
        <div id="app" v-if="appReady">
            <breakpoint-debugger v-if="bpDebug"></breakpoint-debugger>

            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>

        </div>
    </transition>

</template>


<script>

import BreakpointDebugger from '@/components/utilities/BreakpointDebugger';

export default {

    name: 'App',

    metaInfo() {
        return {
            title: 'National Geographic : Everest Educational Interactives',
            meta: [
                {
                    name: 'description',
                    content: 'Make sure you check with an experienced guide before attempting to summit Mount Everest.'
                }, {
                    name: 'keywords',
                    content: 'national geographic, natgeo, everest, mt everest, mt. everest, mount everest, climbing, summit, summiting, explore'
                }, {
                    property: 'og:title',
                    content: 'Everest Educational Interactives'
                }, {
                    property: 'og:type',
                    content: 'website'
                }, {
                    property: 'og:url',
                    content: process.env.VUE_APP_BASE_URL
                }, {
                    property: 'og:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/everest-app-share-image.png'
                }, {
                    property: 'site_name',
                    content: 'National Geographic : Everest Educational Interactives'
                }, {
                    property: 'og:description',
                    content: 'Make sure you check with an experienced guide before attempting to summit Mount Everest.'
                }, {
                    property: 'twitter:card',
                    content: 'summary'
                }, {
                    property: 'twitter:url',
                    content: process.env.VUE_APP_BASE_URL
                }, {
                    property: 'twitter:title',
                    content: 'Everest Educational Interactives'
                }, {
                    property: 'twitter:description',
                    content: 'Make sure you check with an experienced guide before attempting to summit Mount Everest.'
                }, {
                    property: 'twitter:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/everest-app-share-image.png'
                }
            ]
        };
    },

    components: {
        BreakpointDebugger
    },

    data() {
        return {
            bpDebug: (process.env.NODE_ENV !== 'production'),
            appReady: false
        };
    },

    created() {

    },

    mounted() {
        this.$nextTick(() => {
            this.appReady = true;
        });
    }

};
</script>


<style lang="scss">
#app {
    font-family: $base-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $base-font-color;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
</style>
