<template>

    <div class="step4content">

        <h1>calculating your O<sup>2</sup> use</h1>
        <h2 class="mt-1">The average climb from Camp IV to the Summit and back is <span>18 hours</span>.</h2>
    
        <img class="mt-2" src="@/assets/images/oxygen-calculator/img-step4-content.png">

        <div class="tip mt-3">
            <h3>Here’s a tip</h3>
            <p>If you want to be extra cautious, consider stashing a bottle or two on your way up the mountain. That way, your Os will be waiting for you on the way down, and you won’t be burdened by the extra weight as you try to summit.</p>
        </div>
        
    </div>

</template>


<script>

export default {

    name: 'Step4Content',
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
}

</script>


<style lang="scss" scoped>

.step4content {
    color: #4d5384;
    font-size: 18px;
    font-weight: $font-weight-medium;

    & h1 {
        color: #0f005d;
        text-transform: uppercase;
        font-weight: $font-weight-extrabold;
        font-size: 16px;
        margin: 0;
        letter-spacing: 1px;

        @media (min-width: 576px) { // RED (SM)
            font-size: 27px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & sup {
            position: relative;
            top: 2px;
            font-size: 10px;

            @media (min-width: 576px) { // RED (SM)
                top: 1px;
                font-size: 15px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }
    }

    & h2 {
        color: #4d5384;
        font-weight: $font-weight-medium;
        margin: 0;
        font-size: 15px;
        line-height: 24px;

        @media (min-width: 576px) { // RED (SM)
            font-size: 17px;
            line-height: 25px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & span {
            font-weight: $font-weight-bold;
        }
    }

    & img {
        max-width: 510px;
        width: 110%;
        margin-left: -12px;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .tip {
        border-left: 2px solid #4c7bbb;
        text-align: left;
        padding-left: 15px;
        font-size: 14px;
        line-height: 20px;
        max-width: 550px;
        margin: 0 auto;

        @media (min-width: 576px) { // RED (SM)
            font-size: 15px;
            line-height: 24px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & h3 {
            font-size: 15px;
            font-weight: $font-weight-extrabold;
            text-transform: uppercase;
            color: #101f96;
            margin: 0;

            &::before {
                content: '';
                display: inline-block;
                background: transparent url('../../assets/images/oxygen-calculator/img-step4-tip-icon.png') 0 0 no-repeat;
                background-size: cover;
                width: 11px;
                height: 15px;
                margin-right: 5px;
            }
        }

        & p {
            color: #424971;
            margin-top: 10px;
            font-weight: $font-weight-medium;
        }
    }

    & p {
        margin: 0;
        
    }
}

</style>
