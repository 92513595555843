<template>

    <div class="step3content">

        <h1 class="mb-1">Breathing rate for one bottle</h1>
    
        <ul>
            <li v-for="(item, idx) in content"
                :key="'step3-card_' + idx">
                <card
                    :clouds="{ left: { enabled: false, pinTo: 'top' }, right: { enabled: false, pinTo: 'bottom' } }">
                    <template>
                        <h2>{{ item.title }}</h2>
                        <img :src="item.img_src">
                    </template>
                </card>
            </li>
        </ul>

        <p class="mt-2">Most climbers set their bottles to <span>2-liters</span> a minute for most of the climb.</p>
        
    </div>

</template>


<script>

import Card from '@/components/oxygen-calculator/Card'

export default {

    name: 'Step3Content',

    components: {
        Card,
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',

            content: [
                {
                    title: '1-liter a minute',
                    img_src: require('@/assets/images/oxygen-calculator/img-step3-content1.png')
                },
                {
                    title: '2-liters a minute',
                    img_src: require('@/assets/images/oxygen-calculator/img-step3-content2.png')
                },
                {
                    title: '3-liters a minute',
                    img_src: require('@/assets/images/oxygen-calculator/img-step3-content3.png')
                },
                {
                    title: '4-liters a minute',
                    img_src: require('@/assets/images/oxygen-calculator/img-step3-content4.png')
                }
            ]
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
}

</script>


<style lang="scss" scoped>

.card {
    padding: 20px 20px;

    @media (min-width: 576px) { // RED (SM)
        padding: 2rem;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }
}

.step3content {
    color: #0f005d;
    font-weight: $font-weight-extrabold;
    text-transform: uppercase;

    & h1 {
        font-size: 17px;
        margin: 0;

        @media (min-width: 576px) { // RED (SM)
            font-size: 22px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & p {
        text-transform: none;
        font-size: 15px;
        line-height: 24px;
        font-weight: $font-weight-medium;
        margin: 0 auto;
        width: 100%;

        @media (min-width: 576px) { // RED (SM)
            width: 400px;
            font-size: 18px;
            line-height: 29px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & span {
            font-weight: $font-weight-bold;
        }
    }

    & ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
        max-width: 1080px;

        & li {
            margin: 7px 7px;

            @media (min-width: 576px) { // RED (SM)
                margin: 12px 12px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & h2 {
                font-size: 11px;
                margin: 0 0 15px 0;

                @media (min-width: 576px) { // RED (SM)
                    font-size: 15px;
                    margin: 0 0 30px 0;
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }
            }

            & img {
                width: 94px;

                @media (min-width: 576px) { // RED (SM)
                    width: 153px;
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }
            }
        }
    }
}

</style>
