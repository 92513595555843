<template>

    <div class="step1content">
    
        <img src="@/assets/images/oxygen-calculator/img-step1-content.png">
        <p class="mt-2">Bottled oxygen is made of the same air you breathe in and out, but that air is compressed and kept under pressure, so the molecules take up less space.</p>
        
    </div>

</template>


<script>

export default {

    name: 'Step1Content',
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
}

</script>


<style lang="scss" scoped>

.step1content {
    color: #4d5384;
    font-size: 15px;
    line-height: 24px;
    font-weight: $font-weight-medium;
    height: 412px;

    @media (min-width: 576px) { // RED (SM)
        height: 433px;
        font-size: 18px;
        line-height: 29px;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & img {
        width: 250px;

        @media (min-width: 576px) { // RED (SM)
            width: 280px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & p {
        margin: 0;
        max-width: 600px;
        margin: 0 auto;
    }
}

</style>
