/* SHERPA-SPEAK */

import { SET_PLAYED_STATE } from './actions';
import { UPDATE_PLAYED_STATE } from './mutations';

const state = {
    soundboard: [
        {
            slug: 'monday',
            phrase: {
                english: 'Monday',
                tibetan: 'Gza Dawa'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-monday.mp3')
            },
            group: 'days'
        }, {
            slug: 'tuesday',
            phrase: {
                english: 'Tuesday',
                tibetan: 'Gza Mingmar'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-tuesday.mp3')
            },
            group: 'days'
        }, {
            slug: 'wednesday',
            phrase: {
                english: 'Wednesday',
                tibetan: 'Gza Lhakpa'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-wednesday.mp3')
            },
            group: 'days'
        }, {
            slug: 'thursday',
            phrase: {
                english: 'Thursday',
                tibetan: 'Gza Phuru'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-thursday.mp3')
            },
            group: 'days'
        }, {
            slug: 'friday',
            phrase: {
                english: 'Friday',
                tibetan: 'Gza Pasang'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-friday.mp3')
            },
            group: 'days'
        }, {
            slug: 'saturday',
            phrase: {
                english: 'Saturday',
                tibetan: 'Gza Pemba'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-saturday.mp3')
            },
            group: 'days'
        }, {
            slug: 'sunday',
            phrase: {
                english: 'Sunday',
                tibetan: 'Gza Nima'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-sunday.mp3')
            },
            group: 'days'
        }, {
            slug: 'hello',
            phrase: {
                english: 'Hello',
                tibetan: 'Tashi Delek'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-hello.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'how-are-you',
            phrase: {
                english: 'How are you?',
                tibetan: 'Thangbu?'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-how-are-you.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'im-fine',
            phrase: {
                english: 'I\'m fine.',
                tibetan: 'Nga Thangburang weh'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-im-fine.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'yes',
            phrase: {
                english: 'Yes',
                tibetan: 'Hin'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-yes.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'no',
            phrase: {
                english: 'No',
                tibetan: 'Min'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-no.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'thank-you',
            phrase: {
                english: 'Thank you',
                tibetan: 'Thuchiche'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-thank-you.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'thanks',
            phrase: {
                english: 'Thanks!',
                tibetan: 'Thuche'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-thanks.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'sorry',
            phrase: {
                english: 'Sorry',
                tibetan: 'Thulishhyak'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-sorry.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'excuse-me',
            phrase: {
                english: 'Excuse me',
                tibetan: 'Kyok Khiini la'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-excuse-me.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'goodbye',
            phrase: {
                english: 'Goodbye',
                tibetan: 'Kholi pheb'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-good-bye.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'im-hungry',
            phrase: {
                english: 'I\'m hungry.',
                tibetan: 'Nga Lhowa Ihasung'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-im-hungry.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'im-not-hungry',
            phrase: {
                english: 'I\'m not hungry.',
                tibetan: 'Nga Lhowarang me'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-im-not-hungry.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'im-thirsty',
            phrase: {
                english: 'I\'m thirsty.',
                tibetan: 'Nga Komba Iasung'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-im-thirsty.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'im-not-thirsty',
            phrase: {
                english: 'I\'m not thirsty.',
                tibetan: 'Nga Kombarang me'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-im-not-thirsty.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'i-am-tired',
            phrase: {
                english: 'I am tired.',
                tibetan: 'Nga En chesung'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-im-tired.mp3')
            },
            group: 'phrases'
        }, {
            slug: 'how-far-is-everest-from-here',
            phrase: {
                english: 'How far is Everest from here?',
                tibetan: 'Dene Everest cho thakringbu wai?'
            },
            audio: {
                tibetan: require('../../assets/audio/sherpa-speak/vo-how-far-is-everest-from-here.mp3')
            },
            group: 'mastery'
        }
    ],
    played: []
};

const getters = {

    soundboard: ({ soundboard }) => soundboard,

    soundsByGroup: (state) => (group) => {
        return state.soundboard.filter(obj => obj.group === group) || null;
    },

    playedSounds: ({ played }) => played,

    playedBySlug: (state) => (slug) => {
        return state.played.find(obj => obj.slug === slug) || null;
    }

};

const mutations = {

    [UPDATE_PLAYED_STATE](state, payload) {
        //console.log('%cUPDATE_PLAYED_STATE', 'color:lime;', payload);

        let soundIdx = state.played.findIndex(sound => sound.slug === payload.slug);

        if (soundIdx === -1) {
            state.played.push({ slug: payload.slug });
        }
    }

};

const actions = {

    [SET_PLAYED_STATE]({ commit }, payload) {
        //console.log('%cSET_PLAYED_STATE', 'color:lime;', payload);
        commit(UPDATE_PLAYED_STATE, payload);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
