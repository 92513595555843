<template>

    <div class="breakpoint-debugger"></div>

</template>

<script>

    export default {
    
        name: 'BreakpointDebugger',
        
        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

.breakpoint-debugger { // WHITE (XS)
    background-color: white;
    width: 100%;
    height: 3px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.33);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    @media (min-width: 576px) { // RED (SM)
        background-color: #ed1212;
    }
    @media (min-width: 768px) { // GREEN (MD)
        background-color: #42a33c;
    }
    @media (min-width: 992px) { // BLUE (LG)
        background-color: #438dd3;
    }
    @media (min-width: 1200px) { // YELLOW (XL)
        background-color: #e6e845;
    }
    @media (min-width: 1400px) { // PURPLE (XXL)
        background-color: #9901b4;
    }

    /*
    @media (min-width: 576px) { // RED (SM)

    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }
    */

}

</style>
