<template>

    <div class="interactive-nav-list">

        <ul>
            <li v-for="(interactive, idx) in interactives"
                :key="interactive.name"
                :data-idx="idx + 1"
                data-aos="fade-up"
                :data-aos-delay="(idx === 0 ? 400 : (200 * (idx + 2)))"
                data-aos-easing="ease-out-back">
                <router-link :to="{ name: interactive.routeName }">
                    <picture>
                        <source v-for="image in interactive.images" :srcset="image.source" :type="image.type" :key="image.source" />
                        <img :src="interactive.images[interactive.images.length - 1].source" width="512" height="512" :alt="interactive.name" />
                    </picture>
                </router-link>
            </li>
        </ul>

    </div>

</template>

<script>

export default {

    name: 'InteractiveNavList',

    data() {
        return {

            componentName: this.$options.name + ' Component',

            interactives: [
                {
                    name: 'Effects of Altitude',
                    routeName: 'HumanBody',
                    images: [
                        {
                            source: require('@/assets/images/icons/app-icon-human-body-512.webp'),
                            type: 'image/webp'
                        }, {
                            source: require('@/assets/images/icons/app-icon-human-body-512.png'),
                            type: 'image/png'
                        }
                    ],
                    description: ''
                }, {
                    name: 'Talk to Your Team',
                    routeName: 'SherpaSpeak',
                    images: [
                        {
                            source: require('@/assets/images/icons/app-icon-sherpa-speak-512.webp'),
                            type: 'image/webp'
                        }, {
                            source: require('@/assets/images/icons/app-icon-sherpa-speak-512.png'),
                            type: 'image/png'
                        }
                    ],
                    description: ''
                }, {
                    name: 'Planning Your Route',
                    routeName: 'ArLanding',
                    images: [
                        {
                            source: require('@/assets/images/icons/app-icon-ar-terrain-512.webp'),
                            type: 'image/webp'
                        }, {
                            source: require('@/assets/images/icons/app-icon-ar-terrain-512.png'),
                            type: 'image/png'
                        }
                    ],
                    description: ''
                }, {
                    name: 'Calculate Extra Os',
                    routeName: 'OxygenCalculatorLanding',
                    images: [
                        {
                            source: require('@/assets/images/icons/app-icon-oxygen-calculator-512.webp'),
                            type: 'image/webp'
                        }, {
                            source: require('@/assets/images/icons/app-icon-oxygen-calculator-512.png'),
                            type: 'image/png'
                        }
                    ],
                    description: ''
                },
            ]

        };
    }

}

</script>


<style scoped lang="scss">

.interactive-nav-list {

    & ul {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, 140px);
        gap: 1.25rem;
        justify-content: center;
        align-content: center;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            grid-template-columns: repeat(auto-fit, minmax(150px, 256px));
        }
        @media (min-width: 992px) { // BLUE (LG)
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & > li {

            & a {
                display: grid;
                grid-template-rows: max-content auto 1fr;
                //gap: 0.5rem;
                transition: all 0.3s $easeOutQuad;

                @media(hover: hover) {
                    &:hover {
                        transform: scale(1.02);
                        transition: all 0.3s $easeOutQuad;

                        & img {
                            filter: drop-shadow(0 10px 16px $sherpa-title-color);
                            transition: all 0.3s $easeOutQuad;
                        }
                    }
                }
            }

            & header {
                font-size: 0.875rem;
                font-weight: 700;
            }

            & img {
                object-fit: cover;
                max-width: 100%;
                height: auto;
                transition: all 0.3s $easeOutQuad;
            }
        }
    }
}

</style>
