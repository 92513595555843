/* HUMAN-BODY */

//import { SOMETHING } from './actions';
//import { SOMETHING } from './mutations';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
