import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/ar-terrain',
        // route level code-splitting
        // this generates a separate chunk (ar-terrain.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "ar-terrain" */ '../views/ar-terrain/ArTerrain.vue'),
        children: [
            {
                path: 'experience',
                name: 'ArExperience',
                component: () => import(/* webpackChunkName: "ar-experience" */ '../views/ar-terrain/ArExperience.vue')
            },
            {
                path: 'intro',
                name: 'ArOnBoarding',
                component: () => import(/* webpackChunkName: "ar-on-boarding" */ '../views/ar-terrain/ArOnBoarding.vue')
            }, {
                path: '',
                name: 'ArLanding',
                component: () => import(/* webpackChunkName: "ar-landing" */ '../views/ar-terrain/ArLanding.vue')
            }
        ]
    },
    {
        path: '/human-body',
        // route level code-splitting
        // this generates a separate chunk (human-body.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "human-body" */ '../views/human-body/HumanBody.vue'),
        children: [
            {
                path: '/human-body',
                name: 'HumanBody',
                component: () => import(/* webpackChunkName: "human-body-landing" */ '../views/human-body/HumanBodyLanding.vue')
            },
            {
                path: 'experience',
                name: 'HumanBodyExperience',
                component: () => import(/* webpackChunkName: "human-body-experience" */ '../views/human-body/HumanBodyExperience.vue')
            }
        ]
    },
    {
        path: '/oxygen-calculator',
        // route level code-splitting
        // this generates a separate chunk (oxygen-calculator.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "oxygen-calculator" */ '../views/oxygen-calculator/OxygenCalculator.vue'),
        children: [
            {
                path: 'intro',
                name: 'OxygenCalculatorOnBoarding',
                component: () => import(/* webpackChunkName: "oxygen-calculator-on-boarding" */ '../views/oxygen-calculator/OxygenCalculatorOnBoarding.vue')
            }, {
                path: 'calculator',
                name: 'OxygenCalculatorWidget',
                component: () => import(/* webpackChunkName: "oxygen-calculator-widget" */ '../views/oxygen-calculator/OxygenCalculatorWidget.vue')
            }, {
                path: '',
                name: 'OxygenCalculatorLanding',
                component: () => import(/* webpackChunkName: "oxygen-calculator-landing" */ '../views/oxygen-calculator/OxygenCalculatorLanding.vue')
            }
        ]
    },
    {
        path: '/sherpa-speak',
        name: 'SherpaSpeak',
        // route level code-splitting
        // this generates a separate chunk (sherpa-speak.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "sherpa-speak" */ '../views/sherpa-speak/SherpaSpeak.vue')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
];

const router = new VueRouter({

    mode: 'history',

    base: process.env.BASE_URL,

    routes

});

export default router;
