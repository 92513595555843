/* AR-TERRAIN */

import { SET_CURRENT_STEP } from './actions';
import { UPDATE_CURRENT_STEP } from './mutations';

import Step1Content from '@/components/ar-terrain/Step1Content';
import Step2Content from '@/components/ar-terrain/Step2Content';
import Step3Content from '@/components/ar-terrain/Step3Content';

const state = {
    currentStep: null,
    tutorialSteps: [
        {
            step: 1,
            component: Step1Content
        }, {
            step: 2,
            component: Step2Content
        }, {
            step: 3,
            component: Step3Content
        }
    ]
};

const getters = {
    currentStep: ({ currentStep, tutorialSteps }) => currentStep || tutorialSteps[0],

    onBoardingSteps: ({ tutorialSteps }) => tutorialSteps || []
};

const mutations = {
    [UPDATE_CURRENT_STEP](state, payload) {
        state.currentStep = payload;
    }
};

const actions = {
    [SET_CURRENT_STEP]({ commit }, payload) {
        commit(UPDATE_CURRENT_STEP, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
